<template>
	<v-app>
		<v-app-bar
		app
		color="cyan darken-1"
		dark
		>

			<v-spacer></v-spacer>

			<div class="d-flex align-center">
				<v-img
				alt="Oki Logo"
				class="shrink mx-2"
				contain
				src="@/assets/img/logo.png"
				transition="scale-transition"
				width="40"
				/>

				<v-toolbar-title
					class="font-weight-bold"
					>
					WAKA <v-icon>mdi-star</v-icon> OKINAWA
				</v-toolbar-title>
				
				<v-img
					alt="Oki Logo"
					class="shrink mx-2"
					contain
					src="@/assets/img/logo.png"
					transition="scale-transition"
					width="40"
					/>
			</div>

			<v-spacer></v-spacer>

		</v-app-bar>

		<v-main>
			<v-container>
				<div v-for="(item, i) in items" :key="i" >
					<day-card :item="item" />
				</div>
			</v-container>
		</v-main>

		<v-footer
			dark
			padless
		>
			<v-card
			class="flex"
			flat
			tile
			>
			<v-card-title class="cyan">
				<div class="font-weight-bold subtitle-2">ワカ <v-icon>mdi-star</v-icon> オキナワ</div>
				<v-spacer></v-spacer>
				<div class="subtitle-2 font-weight-bold">ケガなく、おうちに帰るまでが旅行です。</div>
			</v-card-title>
			</v-card>
		</v-footer>

	</v-app>
</template>

<script>
import DayCard from './components/DayCard.vue';

export default {
	name: 'App',

	components: {
		DayCard,
	},

	data: () => ({
		items: [
			{
				day: 1,
				img: "https://www.naha-contentsdb.jp/wp-content/uploads/pixta_23935466_L-scaled.jpg",
				schedule: [
					{
						icon: 'alarm',
						time: '6:00',
						message: '起きて〜',
						caption: 'ひとりでるすばんにならないように！',
					},
					{
						icon: 'taxi',
						time: '7:15 〜',
						message: 'タクシー (家〜郡山駅)',
						caption: '荷物、忘れ物がないよーに',
					},
					{
						icon: 'train',
						time: '7:54 〜 9:56',
						message: '新幹線 (郡山〜東京〜浜松町〜羽田空港)',
						caption: 'よっしゃ！沖縄いこーーーー！',
					},
					{
						icon: 'airplane',
						time: '11:45 ~ 14:35',
						message: '飛行機 (羽田空港〜那覇空港)',
						caption: 'すきな えいが あるかな〜？',
					},
					{
						icon: 'train',
						time: '15:00',
						message: 'ゆいレール (那覇空港〜美栄橋)',
						caption: 'ポケモンでんしゃに のれるかな？',
						btn: {
							href: 'https://www.yui-rail.co.jp/common/uploads/ktrededfyutgdf.pdf',
							caption: '運行表',
						}
					},
					{
						icon: 'home-city-outline',
						time: '15:30',
						message: 'ホテル アクアチッタ ナハ',
						caption: 'ベッドにとべ〜 ボヨ〜ん',
						btn: {
							href: 'https://www.resorts.co.jp/aquacitta-naha',
							caption: '詳細',
						}
					},
					{
						icon: 'waves',
						time: '〜 17:00',
						message: 'ホテル で プール にはいる',
						caption: 'プール でっかいかな〜',
					},
					{
						icon: 'walk',
						time: '18:00 〜 21:00',
						message: '国際通り',
						caption: 'おいしいもん食べるぞー',
					},
					{
						icon: 'bathtub-outline',
						time: '21:00 〜',
						message: 'ホテル',
						caption: '明日はいっぱい遊ぶぞー',
					},
				],
			},
			{
				day: 2,
				img: "https://www.okinawastory.jp/files/attractions/000/001/104/images_45FzPnHCPw9VU0RMkRwMwNM0UusWujbQaxzGdHjQ-w800.jpg",
				schedule: [
					{
						icon: 'alarm',
						time: '7:30',
						message: '起きて〜',
						caption: 'あさごはん いらないなら ねてて〜',
					},
					{
						icon: 'car',
						time: '8:00',
						message: 'レンタカー (パパ7:30ホテル発)',
						caption: 'でか〜いクルマだといいね',
					},
					{
						icon: 'coffee',
						time: '何時？',
						message: '朝ごはん',
						caption: 'どこいくの？',
					},
					{
						icon: 'shopping',
						time: '食べたら 〜 10:45',
						message: '自由時間',
						caption: 'ママのいきたいところ',
					},
					{
						icon: 'car',
						time: '10:45 〜 11:15',
						message: '移動',
						caption: '海見ながら行くぞ〜',
						btn: {
							href: 'https://goo.gl/maps/B1mWLSnifDZZxgkNA',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'tsunami',
						time: '11:30 〜 14:30',
						message: '宜野湾マリーナ',
						caption: 'パラセーリング / ジェット ちょっとこわいな〜',
						btn: {
							href: 'https://www.asoview.com/item/activity/pln3000033374/',
							caption: 'アソビュー',
						}
					},
					{
						icon: 'car',
						time: '14:30 〜 15:30',
						message: '移動',
						caption: 'とちゅうで なんか 食べよう',
						btn: {
							href: 'https://goo.gl/maps/gHgLthPLSG8iGSeg8',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'tsunami',
						time: '15:30 〜 17:00',
						message: '万座ビーチ',
						caption: '鬼ごっこしよー　つかまらないぞー',
					},
					{
						icon: 'car',
						time: '17:00 〜 17:30',
						message: '移動',
						caption: 'ああ楽しかった！',
						btn: {
							href: 'https://goo.gl/maps/5PQ2vRPaW2Si79zj7',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'food-fork-drink',
						time: '17:30 〜 18:30',
						message: 'やんばる物産センター',
						caption: 'なんか食べよう すきなもの買おう',
						btn: {
							href: 'https://www.resorts.co.jp/aquacitta-naha',
							caption: '詳細',
						}
					},
					{
						icon: 'car',
						time: '18:30 〜 19:30',
						message: '移動(40分) + 夜ごはん買おう',
						caption: '',
						btn: {
							href: 'https://goo.gl/maps/od4H8zc3PhqC8rNA9',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'bathtub-outline',
						time: '19:30 〜',
						message: 'ロワジール テラス ＆ ヴィラズ 古宇利',
						caption: '絶対明日筋肉痛だーーーーーーー',
					},
				],
			},
			{
				day: 3,
				img: "https://tori-dori.com/wp/wp-content/uploads/PG19-073328.jpg",
				schedule: [
					{
						icon: 'alarm',
						time: '8:00',
						message: '起きて〜',
						caption: 'あささんぽしよ〜',
					},
					{
						icon: 'car',
						time: '8:00 〜 9:00',
						message: 'ハートロックみよ〜',
						caption: '嵐のCMだぞ！',
					},
					{
						icon: 'coffee',
						time: '何時？',
						message: '朝ごはん',
						caption: 'どこいくー？',
					},
					{
						icon: 'baby-carriage',
						time: '9:15 〜 9:30',
						message: 'トゥクトゥク',
						caption: 'ホテルから海まで乗ろう',
						btn: {
							href: 'https://www.loisirterrace.com/kouri/facility_service/',
							caption: 'トゥクトゥクって何？',
						}
					},
					{
						icon: 'tsunami',
						time: '9:30 〜 11:00',
						message: 'ザンマリン',
						caption: 'シュノーケリング。　チンアナゴとカクレクマノミみれるかな？',
						btn: {
							href: 'https://goo.gl/maps/qFVPim1Cc6VATHSy7',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'tsunami',
						time: '11:00 〜 12:00',
						message: '古宇利ビーチ？',
						caption: '泳ごうか？',
					},
					{
						icon: 'car',
						time: '12:00 〜 12:20',
						message: '移動',
						caption: 'どこで食べよっか〜',
					},
					{
						icon: 'food-fork-drink',
						time: '12:20 〜 13:00',
						message: 'どこかでランチ',
						caption: 'ソーキそば か 海ぶどうかウニ',
					},
					{
						icon: 'car',
						time: '13:00 〜 13:15',
						message: '移動',
						caption: 'あ〜はらいっぱい',
					},
					{
						icon: 'fish',
						time: '13:15 〜 15:30',
						message: '美ら海水族館',
						caption: 'ジンベエザメーーーーーーー！',
						btn: {
							href: 'https://goo.gl/maps/N1SpbpvoHm8KuHXc8',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'forest',
						time: '15:30 〜 17:00',
						message: 'フクギ並木',
						caption: 'ダラダラいこう〜',
						btn: {
							href: 'https://goo.gl/maps/wq7EXmaAjWJaGbUL8',
							caption: 'googleマップ',
						}
					},
					{
						icon: 'car',
						time: '17:00 〜 17:20',
						message: '移動',
						caption: '夜どこで食べる〜',
					},
					{
						icon: 'food-fork-drink',
						time: '17:20 〜 19:00',
						message: 'どこかでディナー',
						caption: 'なにがいいじゃろ？',
					},
					{
						icon: 'car',
						time: '19:00 〜 19:15',
						message: '移動',
						caption: '夜何しよか〜？',
					},
					{
						icon: 'bathtub-outline',
						time: '19:15 〜',
						message: 'ロワジール テラス ＆ ヴィラズ 古宇利',
						caption: 'なにする〜？',
					},
				],
			},
			{
				day: 4,
				img: "https://kotokurabe.com/makoto/wp-content/uploads/technology/airplane-aircraft-top.jpg",
				schedule: [
					{
						icon: 'alarm',
						time: '7:30',
						message: '起きて〜',
						caption: 'かえるじゅんび〜',
					},
					{
						icon: 'coffee',
						time: '何時？',
						message: '朝ごはん',
						caption: 'どうする？',
					},
					{
						icon: 'car',
						time: '8:30 〜 10:00',
						message: '空港に移動',
						caption: 'レンタカー返却 11時まで',
					},
					{
						icon: 'food-fork-drink',
						time: '10:30 〜 11:50',
						message: '空港でおみやげ',
						caption: 'なに買おうかなー？',
					},
					{
						icon: 'airplane',
						time: '12:10 〜 14:35',
						message: '飛行機 (那覇空港〜羽田空港)',
						caption: '機内で食べるか',
					},
					{
						icon: 'train',
						time: '15:30 〜 17:30',
						message: '新幹線 (羽田空港〜浜松町〜東京〜郡山)',
						caption: 'おうち帰りたくないーーーーー！',
					},
					{
						icon: 'taxi',
						time: '17:00 〜 18:00',
						message: 'タクシー (郡山駅〜家)',
						caption: '明日、学校、幼稚園、仕事やだーーー',
					},
				],
			},
		],
	}),
};
</script>

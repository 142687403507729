<template>
	<v-card
		class="mx-auto my-12"
		max-width="720"
		>
		<v-card-title class="cyan white--text font-weight-bold justify-center">DAY {{ item.day }}</v-card-title>
		<v-img
			height="250"
			:src="item.img"
			>
		</v-img>

		<v-card-text class="py-0">
			<v-timeline
				align-top
				dense
			>
				<v-timeline-item
				v-for="(item, i) in item.schedule"
				:key="i"
				:color="i%2==0 ? 'pink' : 'cyan'"
				small
				class="py-8"
				>
				
				<v-row>
					<v-col cols="12" sm="4">
						<strong>
							<v-icon class="mr-1">mdi-{{ item.icon }}</v-icon>
							{{ item.time }}
						</strong>
					</v-col>
					<v-col>
						<div class="text-h6 black--text font-weight-bold">
							{{ item.message }}
						</div>
						<div class="text-caption">
							{{ item.caption }}
						</div>
						<v-btn
							v-if="item.btn"
							dark
							:color="i%2==0 ? 'pink ' : 'cyan'"
							class="mt-2"
							:href="item.btn.href"
							target="_blank"
							small
							>
							{{ item.btn.caption }}
						</v-btn>
					</v-col>
				</v-row>
				</v-timeline-item>
			</v-timeline>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: ['item'],

	// data: () => ({
	// 	schedule: [
    //   {
	// 			icon: 'alarm',
	// 			time: '7:30',
	// 			message: '起きて〜',
	// 			caption: 'かえるじゅんび〜',
	// 		},
	// 		{
	// 			icon: 'coffee',
	// 			time: '何時？',
	// 			message: '朝ごはん',
	// 			caption: 'どうする？',
	// 		},
	// 		{
	// 			icon: 'car',
	// 			time: '8:30 〜 10:00',
	// 			message: '空港に移動',
	// 			caption: 'レンタカー返却 11時まで',
	// 		},
	// 		{
	// 			icon: 'food-fork-drink',
	// 			time: '10:30 〜 11:50',
	// 			message: '空港でおみやげ',
	// 			caption: 'なに買おうかな〜？',
	// 		},
	// 		{
	// 			icon: 'airplane',
	// 			time: '12:10 ~ 14:35',
	// 			message: '飛行機 (那覇空港〜羽田空港)',
	// 			caption: '機内で食べるか',
	// 		},
	// 		{
	// 			icon: 'train',
	// 			time: '15:30 〜 17:30',
	// 			message: '新幹線 (羽田空港〜浜松町〜東京〜郡山)',
	// 			caption: 'たぶん さみしい 気持ち',
	// 		},
    //   {
	// 			icon: 'taxi',
	// 			time: '17:00 〜 18:00',
	// 			message: 'タクシー (郡山駅〜家)',
	// 			caption: 'つかれた〜〜〜〜',
	// 		},
	// 	],
	// }),

}
</script>
